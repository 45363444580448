<template>
  <div>
    <Toast/>
    <div class="card">
      <Steps :model="items" :readonly="true"/>
    </div>
    <Card>
      <template #header>
      </template>
      <template #title>
        Dirección
      </template>
      <template v-slot:subtitle>
        Añadir banca
      </template>
      <template #content>
        <div class="field">
          <!--         Estado-->
          <Dropdown v-model="selectedEstado" :options="estados"
                    optionLabel="testado" optionValue="id_estado"
                    placeholder="Seleccionar Estado" class="filterItem" :loading="loadingEstado"
                    :showClear="true"
                    style="width: 100%"
                    :onChange="refreshMunicipios"
                    :filter="true"/>
          <!--        Municipio-->
          <Dropdown v-model="selectedMunicipio" :options="municipios"
                    optionLabel="tmunicipio" optionValue="id_municipio"
                    placeholder="Seleccionar Municipio" class="filterItem" :loading="loadingMunicipio"
                    :showClear="true"
                    v-if="selectedEstado"
                    style="width: 100%"
                    :filter="true"/>
        </div>
      </template>
      <template #footer>
        <div class="grid grid-nogutter justify-content-between">
          <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
          <Button label="Siguiente"
                  @click="nextPage()"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  :disabled="this.selectedMunicipio ? disabled : ''"/>
        </div>
      </template>
    </Card>

    <router-view v-slot="{Component}" @prevPage="prevPage($event)" @nextPage="nextPage($event)">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>
  </div>
  <Toast/>
</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import BankingService from "@/service/backend/BankingService";

export default {
  name: "Step3",
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService;
    this.bankingService = new BankingService;
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
  },
  mounted() {
    this.refreshEstados();
    this.selectedEstado = this.$store.getters.getBanking.id_estado;
    this.refreshMunicipios();
    this.selectedMunicipio = this.$store.getters.getBanking.id_municipio;
  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/bancas/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/bancas/agregar-2'
        },
        {
          label: 'Dirección',
          to: '/bancas/agregar-3'
        },
      ],
      selectedEstado: null,
      selectedMunicipio: null,
      estados: null,
      municipios: null,
      loadingEstado: false,
      loadingMunicipio: false,
      params: null,
      pageIndex: 1,
      id_psxcmxban: null,
    }
  },
  methods: {
    async refreshEstados() {
      this.municipios = null;
      this.estados = await this.mainService.getEstados();
    },
    async refreshMunicipios() {
      if (this.selectedEstado) {
        this.municipios = await this.mainService.getMunicipios({id_estado: this.selectedEstado});
      }
    },
    async createBanking() {
      let response;
      let result;
      try {
        response = await this.bankingService.createBanking({
          tbanca: this.$store.getters.getBanking.bankingName,
          id_comercializador: this.$store.getters.getBanking.id_comercializador,
          id_banca: this.$store.getters.getBanking.id_banca,
          id_estado: this.$store.getters.getBanking.id_estado,
          id_municipio: this.$store.getters.getBanking.id_municipio,
          trif_banca: '',
        });
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo crear el banca ${this.$store.getters.getBanking.bankingName}| ` + e.message,
          life: 10000
        });
        this.overlay = false;
        result = false;
      }

      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo crear el banca ${this.$store.getters.getBanking.bankingName}| ` + response.error,
          life: 10000
        });
        this.overlay = false;
        result = false;
      } else {
        this.$toast.add({
          severity: 'success',
          summary: '',
          detail: `Banca ${this.$store.getters.getBanking.bankingName} creada.`,
          life: 3000
        });
        this.overlay = false;
        result = true;
      }
      return result;
    },
    async nextPage() {
      this.$store.commit('setBanking', {
        bankingName: this.$store.getters.getBanking.bankingName,
        id_comercializador: this.$store.getters.getBanking.id_comercializador,
        id_banca: this.$store.getters.getBanking.id_banca,
        id_estado: this.selectedEstado,
        id_municipio: this.selectedMunicipio,
        id_psxcmxban: this.id_psxcmxban,
      });
      if (await this.createBanking()) {
        this.$router.push('/bancas/');
      }
    },
    prevPage() {
      this.$store.commit('setBanking', {
        bankingName: this.$store.getters.getBanking.bankingName,
        id_comercializador: this.$store.getters.getBanking.id_comercializador,
        id_estado: this.selectedEstado,
        id_municipio: this.selectedMunicipio,
        id_banca: this.idBanking,
        id_psxcmxban: this.id_psxcmxban,
      });
      this.$router.push('/bancas/agregar-2');
    },
  }
}
</script>

<style scoped lang="scss">
.filterItem {
  margin: 20px 0 5px 0;
}

</style>